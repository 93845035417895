@use './setting.scss' as var;

.body-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.listmark {
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 0.7em;
  text-indent: -0.7em;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
}
.attention {
  color: var.$attention;
}
.inner {
  @include var.pc {
    max-width: 1010px;
    width: 78%;
    margin: 0 auto;
  }
}
.select-label {
  background: var.$bg8;
  color: #fff;
  padding: 9px 11px;
  border-radius: 6px;
  font-size: 12px;
  letter-spacing: 0.96px;
  text-align: center;
  white-space: nowrap;
}
.modal-close {
  position: absolute;
  top: -30px;
  right: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  cursor: pointer;
  text-align: center;
  background: transparent;
  font-size: 0;
  @include var.sp {
    top: -34px;
    width: 24px;
    height: 24px;
    font-size: 0;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 2px;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    transform-origin: center;
    -moz-transform-origin: center;
    -webkit-transform-origin: center;
    background: #000;
  }
  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(-135deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(-135deg);
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-135deg);
  }
}
.btn-wrap {
  margin-top: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ボタン
.btn {
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: var.$bg9;
  background: #fff;
  border: 2px solid var.$bg9;
  border-radius: 100px;
  padding: 19px 40px;
  margin: 5px 2px;
  cursor: pointer;
  transition-property: color, background-color, opacity;
  transition-timing-function: var.$ease;
  transition-duration: 0.6s;
  &.active {
    background: var.$bg9;
    color: #fff;
  }
  @include var.pc {
    &:hover:not(:disabled) {
      background: var.$bg9;
      color: #fff;
    }
  }
  &.active {
    cursor: default;
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &-sm {
    padding: 7px 22px;
  }
  &-search {
    max-width: 280px;
    width: 100%;
    color: #fff;
    background: var.$attention;
    border: none;
    @include var.sp {
      font-size: 4vw;
      letter-spacing: 2px;
    }
    @include var.pc {
      &:hover:not(:disabled) {
        background: var.$attention;
        opacity: 0.6;
      }
    }
  }
}

// 入力フォーム
.input {
  width: 200px;
  height: 48px;
  color: #3e3e3e;
  padding: 14px;
  border: 1px solid #3e3e3e;
  border-radius: 8px;
  margin: 5px 0;
  &-sm {
    width: 150px;
  }
  + input {
    margin-left: 10px;
  }
}

// エラーメッセージ
.error-message {
  margin-top: 20px;
  color: var.$attention;
}
.under {
  &-page {
    padding: 30px;
    @include var.sp {
      padding: 5%;
      min-height: calc(100vh - 82px - 102px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &-wrap {
    > li {
      margin-bottom: 40px;
      &:not(:first-of-type) {
        padding-top: 40px;
        border-top: 1px solid var.$grey1;
      }
    }
  }
}
.ttl {
  &-header {
    font-size: 36px;
    color: var.$c5;
    @include var.font2b();
    text-align: center;
    padding: 20px 0 30px;
    @include var.sp {
      font-size: 8vw;
    }
  }
  &-sub {
    font-size: 24px;
    @include var.sp {
      font-size: 4vw;
    }
    &2 {
      font-size: 16px;
      background: linear-gradient(270deg, var.$bg2, var.$bg3);
      color: #fff;
      display: inline-block;
      padding: 0.2em;
      line-height: 1.2;
      @include var.sp {
        font-size: 3.86vw;
      }
    }
    &3 {
      font-size: 16px;
      @include var.sp {
        font-size: 3.86vw;
      }
    }
  }
}
.ttl,
.ttl-sub,
.ttl-sub2,
.ttl-sub3 {
  @include var.font2b();
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
}
.ttl,
.ttl-sub {
  @include var.sp {
    text-align: center;
  }
}
.tbl-del {
  @include var.sp {
    width: 100%;
  }
  tr {
    border-top: 1px solid #c6cbd1;
  }
  th {
    font-weight: 600;
    padding: 12px 13px;
    border: 1px solid #dfe2e5;
    background: rgba(34, 90, 161, 0.1);
  }
  td {
    font-weight: 600;
    padding: 12px 13px;
    border: 1px solid #dfe2e5;
  }
}
.table-wrap {
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

@charset "utf-8";

// フォント設定
$font: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$font2: noto-sans-cjk-jp, sans-serif;
$font3: source-han-sans-japanese, sans-serif;

// WebFont
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;1,700&display=swap");

// カラーテーマ
$c1: #102961;
$c2: #ffffff;
$c3: #aa000a;
$c4: #05204b;
$c5: #002040;
$c6: #00214b;

// 警戒色
$attention: #a51114;

// グレーテーマ5種
$grey1: #ededed;
$grey2: #d0d0d0;
$black1: #4f4c4c;
$black2: #ededed;

// サイト横幅
$w-site: 1180px;
$wmax-site: 1340px;
$minSp: 415px; // sp座席から選択時タブ調整用
$tablet: 480px;

// ブレイクポイント
$w-sp: 767px;

// map表示最小画面幅
$w-minMap: 1010px;

// 標準テキストカラー
$tc: #0e0e0e;

// 標準背景色
$bg: #ffffff;
$bg2: #09326f;
$bg3: #042040;
$bg4: #002040;
$bg5: #0d2c54;
$bg6: #0000000d;
$bg7: #efefef;
$bg8: #225aa1;
$bg9: #041e37;
$bg10: #045faa;
$bg11: #ff2c2c;
$bg12: #fafafa;

// mapクリッカブル
$mapHover: #bb0000;
$mapClick: #1053a899;

// 標準リンクカラー
$lc: #c93333;

// 標準イージング
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);

// spピンチインアウト案内モーダル横余白
$zoomModalSide: 35px;

// レスポンシブMixin
@mixin sp() {
  @media screen and (max-width: $w-sp) {
    @content;
  }
}
@mixin minSp {
  @media screen and (max-width: $minSp) {
    @content;
  }
}
@mixin tabletSp {
  @media screen and (min-width: $tablet) and (max-width: $w-sp) {
    @content;
  }
}
@mixin pc() {
  @media screen and (min-width: $w-sp + 1) {
    @content;
  }
}
@mixin font2m() {
  font-family: $font2;
  font-weight: 500;
  font-style: normal;
}
@mixin font2r() {
  font-family: $font2;
  font-weight: 400;
  font-style: normal;
}
@mixin font2b() {
  font-family: $font2;
  font-weight: 700;
  font-style: normal;
}
@mixin font3h() {
  font-family: $font3;
  font-weight: 900;
  font-style: normal;
}

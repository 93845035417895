@use './setting.scss' as var;

.page-home {
  height: 100%;
  min-height: 100%;
  header {
    display: none;
  }
  main {
    padding-top: 0;
    @include var.pc {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
  section {
    @include var.pc {
      width: 100%;
    }
  }
  .inner {
    @include var.pc {
      position: relative;
      max-width: 1140px;
      width: 90%;
      margin: 90px auto 0;
      height: 640px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../images/pc-mv.jpg") no-repeat center center;
        background-size: cover;
      }
    }
    &-sp {
      @include var.sp {
        position: relative;
        padding-bottom: 238px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../images/sp-mv.jpg") no-repeat center;
          background-size: cover;
          z-index: -1;
        }
      }
    }
  }
  .emblem {
    @include var.pc {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, -50px, 0);
      -moz-transform: translate3d(-50%, -50px, 0);
      -webkit-transform: translate3d(-50%, -50px, 0);
    }
    @include var.sp {
      padding-top: 15vh;
    }
    .image {
      width: 100px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .ttl {
      font-size: 18px;
      letter-spacing: 1.44px;
      line-height: 2.61;
      text-align: center;
      color: var.$c5;
      &-main {
        margin: 34px auto 5px;
        letter-spacing: 1.12px;
        line-height: 1.28;
        font-size: 27.5px;
        @include var.font3h();
        @include var.sp {
          margin: 4.6vh auto 5px;
        }
      }
      &-sub {
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 1.4;
        @include var.font3h();
        @include var.sp {
          font-size: 4.4vw;
          line-height: 2.6;
        }
      }
    }
  }
}
.search {
  padding-top: 208px;
  @include var.sp {
    padding-top: 3.2vh;
  }
  p {
    margin: 0;
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 56%;
    @include var.sp {
      width: 92%;
    }
  }
  &-btn {
    position: relative;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.96px;
    line-height: 1.5;
    opacity: 0.8;
    width: 50%;
    padding: 0;
    transition: all var.$ease 0.6s;
    border: none;
    @include var.sp {
      font-size: 2.8vw;
    }
    @include var.pc {
      &:hover {
        opacity: 0.5;
        transition: all var.$ease 0.6s;
      }
    }
    &:nth-of-type(odd) {
      background: var.$bg4;
      border-radius: 24px 0px 0px 24px;
    }
    &:nth-of-type(even) {
      background: var.$bg5;
      border-radius: 0px 24px 24px 0px;
    }
    > * {
      color: #fff;
      margin: 0 !important;
    }
    a,
    .modal-link {
      display: block;
      padding: 28px 10px;
      cursor: pointer;
      @include var.pc {
        height: 160px;
      }
    }
  }
  &-flex {
    @include var.pc {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      -moz-transform: translate3d(-50%, 0, 0);
      -webkit-transform: translate3d(-50%, 0, 0);
    }
    .image {
      width: 38px;
      margin-right: 30px;
      @include var.sp {
        width: 56px;
        margin: 25px auto 0;
      }
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 20px;
      letter-spacing: 1.28px;
      line-height: 1.5;
      text-align: left;
      white-space: nowrap;
      span {
        font-size: 16px;
      }
      @include var.sp {
        font-size: 5.8vw;
        letter-spacing: 1.92px;
        line-height: 1.125;
        text-align: center;
        margin-top: 20px;
        span {
          font-size: 4.1vw;
        }
      }
    }
  }
}
.link-wrap {
  @include var.pc {
    padding-top: 160px;
    padding-bottom: 31px;
  }
  @include var.sp {
    background: var.$bg12;
    padding: 20px 0;
  }
  ul {
    @include var.pc {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      @include var.font2m();
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      transition: all var.$ease 0.6s;
      z-index: 0;
      @include var.pc {
        width: 26%;
        &:not(:first-of-type) {
          margin-left: 36px;
        }
      }
      @include var.sp {
        width: 92%;
        font-size: 3.3vw;
        margin: 0 auto;
        box-shadow: 0px 4px 12px var.$bg6;
        &:not(:first-of-type) {
          margin-top: 15px;
        }
      }
      @include var.pc {
        &:hover {
          opacity: 0.7;
          transition: all var.$ease 0.6s;
        }
      }
      > * {
        color: var.$c5;
      }
      a {
        display: block;
        padding: 31px 10px;
        @include var.pc {
          height: 80px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.searchModal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
  @include var.font2b();
  @include var.sp {
    align-items: center;
    justify-content: center;
  }
  &.is-active {
    @include var.sp {
      display: flex;
    }
    @include var.pc {
      display: block;
    }
  }
  &-background {
    background: linear-gradient(
        270deg,
        rgba(9, 50, 111, 0.9) 0%,
        rgba(4, 32, 64, 0.9) 100%
      )
      0% 0% no-repeat padding-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &-inner {
    position: relative;
    width: 384px;
    padding: 47px 20px 40px;
    border-radius: 18px;
    background: #fff;
    opacity: 1;
    margin: 0 auto;
    @include var.pc {
      margin: 10% auto;
    }
    @include var.sp {
      width: 92%;
      padding: 45px 19px 40px;
    }
  }
  &-ttl,
  &-text {
    color: var.$c5;
  }
  &-ttl {
    font-size: 16px;
    letter-spacing: 1.28px;
    line-height: 5;
    @include var.sp {
      font-size: 4.34vw;
      line-height: 1.2;
    }
  }
  &-text {
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 1.35;
    @include var.sp {
      font-size: 3.86vw;
      letter-spacing: 1.28px;
      margin-top: 26px;
    }
  }
  &-box {
    width: 280px;
    margin: 27px auto 0;
    @include var.sp {
      width: 95%;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    &-ttl {
      font-size: 14px;
      letter-spacing: 1.12px;
      line-height: 1.17;
      width: 5em;
      margin-right: 14px;
      text-align: right;
      color: var.$c6;
      @include var.sp {
        font-size: 3.38vw;
        line-height: 3;
      }
    }
    &-select,
    &-input {
      width: 200px;
      height: 48px;
      border-radius: 8px;
      padding: 14px;
      border: 1px solid #3e3e3e;
      color: #3e3e3e;
    }
    &-input {
      @include var.sp {
        font-size: 4.5vw;
      }
      @include var.tabletSp {
        font-size: 18px;
      }
    }
    &-select {
      background: var.$c5;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1.12px;
      line-height: 1.42;
      @include var.sp {
        font-size: 3.38vw;
      }
    }
  }
  .wheelchair {
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 1.17;
    color: #00214b;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    input {
      margin-right: 1em;
      transform: scale(1.5);
    }
  }
  .modal-close {
    top: 18px;
  }
}

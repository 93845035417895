@forward './_reset.scss';
@forward './_header.scss';
@forward './_footer.scss';
@forward './_home.scss';
@forward './_teaser.scss';
@forward './_stadium.scss';
@forward './_edit.scss';
@forward './_components.scss';
@forward './base.scss';
@use './setting.scss' as var;

@include var.pc {
  .sp {
    display: none !important;
  }
  .item {
    overflow: auto;
  }
}
@include var.sp {
  .pc {
    display: none !important;
  }
}
body {
  position: relative;
  font-size: 14px;
  font-family: var.$font;
  color: var.$tc;
  line-height: 1.4;
  @include var.sp {
    font-size: 12px;
  }

  // メディアクエリの状況取得用
  &::after {
    display: none;
    @include var.pc {
      content: "pc";
    }
    @include var.sp {
      content: "sp";
    }
  }
}
.wrapper {
  @include var.pc {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
  }
}
main {
  @include var.pc {
    flex: 1;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 90px;
  }
}
.slide-routes {
  @include var.pc {
    min-height: 100vh;
  }
}
.page-home,
.page-stadium {
  @include var.pc {
    background-color: var.$bg;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 55.5%;
      background: linear-gradient(270deg, var.$bg2, var.$bg3);
    }
  }
}

@use './setting.scss' as var;

footer {
  @include var.pc {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var.$c4;
  }
  @include var.sp {
    height: 102px;
    color: #fff;
    background: linear-gradient(270deg, var.$bg2, var.$bg3);
  }
}
.footer {
  &-inner,
  &-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: var.$c4;
      @include var.sp {
        color: #fff;
      }
    }
  }
  &-right {
    a {
      @include var.pc {
        margin-right: 35px;
      }
    }
  }
  &-inner {
    position: relative;
    padding: 30px;
    @include var.sp {
      padding: 18px 15px 34px;
    }
    .copyright {
      @include var.font2m();
      font-size: 8px;
      line-height: 2;
      @include var.sp {
        position: absolute;
        bottom: 18px;
        right: 15px;
        font-size: 1.93vw;
      }
    }
  }
  &-list {
    @include var.font2b();
    font-size: 11px;
    line-height: 1.2;
    @include var.sp {
      font-size: 2.89vw;
    }
    li {
      transition: all var.$ease 0.6s;
      @include var.pc {
        &:hover {
          opacity: 0.7;
          transition: all var.$ease 0.6s;
        }
      }
    }
  }
  &-ttl {
    margin: 0;
    transition: all var.$ease 0.6s;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -5px;
      border-top: solid 2px var.$c4;
      border-right: solid 2px var.$c4;
      width: 0.5em;
      height: 0.5em;
      transform: rotate(45deg) translate3d(-50%, 0, 0);
      -moz-transform: rotate(45deg) translate3d(-50%, 0, 0);
      -webkit-transform: rotate(45deg) translate3d(-50%, 0, 0);
      transition: all var.$ease 0.3s;
    }
    @include var.sp {
      &::after {
        border-color: #fff;
        right: -6px;
        width: 1em;
        height: 1em;
        transform: rotate(45deg) translate3d(0, -50%, 0);
        -moz-transform: rotate(45deg) translate3d(0, -50%, 0);
        -webkit-transform: rotate(45deg) translate3d(0, -50%, 0);
        @include var.sp {
          right: 1.5em;
        }
      }
    }
    @include var.pc {
      &:hover {
        opacity: 0.7;
        transition: all var.$ease 0.6s;
        &::after {
          right: -7px;
          transition: all var.$ease 0.3s;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-right: 0.5em;
      @include var.sp {
        margin-right: 2.5em;
      }
    }
    img {
      width: 30px;
      margin-right: 7px;
      @include var.sp {
        width: 46px;
      }
    }
    span {
      @include var.font2b();
      font-size: 11px;
      @include var.sp {
        font-size: 2.6vw;
        line-height: 1.18;
        letter-spacing: 0;
      }
    }
  }
}

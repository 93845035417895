@use './setting.scss' as var;

.page-teaser {
  @include var.pc {
    background-color: var.$bg;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 55.5%;
      min-height: 400px;
      background: linear-gradient(270deg, var.$bg2, var.$bg3);
    }
  }
  header {
    display: none;
  }
  main {
    @include var.pc {
      padding-top: 50px;
      padding-bottom: 92px;
      min-height: 542px;
    }
  }
  .inner {
    @include var.pc {
      position: relative;
    }
    @include var.sp {
      padding: 5%;
      min-height: calc(100vh - 102px);
    }
  }
}
.emblem {
  @include var.sp {
    padding-top: 15vh;
  }
  .image {
    width: 100px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .ttl {
    font-size: 18px;
    letter-spacing: 1.44px;
    line-height: 2.61;
    text-align: center;
    color: #fff;
    @include var.sp {
      color: var.$c5;
    }
    &-main {
      margin: 42px auto 5px;
      font-size: 18px;
      letter-spacing: 1.12px;
      line-height: 1.28;
      @include var.font3h();
      @include var.sp {
        font-size: 4.8vw;
        margin: 4.6vh auto 5px;
      }
    }
    &-sub {
      font-size: 18px;
      letter-spacing: 1.44px;
      line-height: 2.6;
      @include var.font3h();
      @include var.sp {
        font-size: 4.4vw;
      }
    }
    &-coming {
      font-size: 40px;
      letter-spacing: 3.2px;
      line-height: 1.175;
      @include var.sp {
        font-size: 9.7vw;
      }
      &-day {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 2.6;
        @include var.sp {
          font-size: 4.4vw;
        }
      }
    }
  }
}

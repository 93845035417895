@use './setting.scss' as var;

header {
  @include var.pc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: linear-gradient(270deg, var.$bg2, var.$bg3);
  }
  @include var.sp {
    height: 82px;
    background: linear-gradient(270deg, var.$bg2, var.$bg3);
  }
}
.header {
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    color: #fff;
    @include var.sp {
      padding: 16px 10px;
    }
  }
  &-ttl {
    transition: all var.$ease 0.6s;
    a {
      display: flex;
      align-items: center;
      justify-content: left;
      color: #fff;
    }
    @include var.pc {
      &:hover {
        opacity: 0.7;
        transition: all var.$ease 0.6s;
      }
      margin-right: 10px;
    }
    img {
      width: 50px;
      margin-right: 2.4vw;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.12px;
      line-height: 1.28;
      @include var.sp {
        font-size: 3.86vw;
      }
    }
  }
}
nav {
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    li {
      transition: all var.$ease 0.6s;
      @include var.pc {
        &:hover {
          opacity: 0.7;
          transition: all var.$ease 0.6s;
        }
        &:not(:first-of-type) {
          margin-left: 30px;
        }
      }
      @include var.sp {
        width: 50px;
      }
      a {
        color: #fff;
        @include var.pc {
          display: flex;
          align-items: center;
        }
        img {
          width: 30px;
          @include var.pc {
            margin-right: 10px;
          }
          @include var.sp {
            margin: 0 10px;
          }
        }
        p {
          @include var.sp {
            font-size: 2vw;
            text-align: center;
          }
        }
      }
    }
  }
}

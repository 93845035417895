@charset "utf-8";

$px: 5;
@for $i from 9 through 23 {
  .fz#{$i + 1} {
    font-size: #{$i + 1}px !important;
  }
}

.fwb {
  font-weight: bold !important;
}
.fwn {
  font-weight: normal !important;
}

.tac {
  text-align: center !important;
}
.tal {
  text-align: left !important;
}
.tar {
  text-align: right !important;
}

@for $i from 0 through 16 {
  .mt#{$i * $px} {
    margin-top: #{$i * 0.3125}rem !important;
  }
  .mb#{$i * $px} {
    margin-bottom: #{$i * 0.3125}rem !important;
  }
}

@use './setting.scss' as var;

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.page-stadium {
  @include var.pc {
    &::before {
      min-width: var.$w-site;
    }
    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .inner {
      position: relative;
      width: var.$w-site;
      max-width: none;
      margin-top: 30px;
    }
    footer {
      min-width: var.$w-site;
    }
  }
  @include var.sp {
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../images/sp-mv.jpg") no-repeat bottom;
      background-size: cover;
      z-index: -1;
      opacity: 0.2;
    }
    footer {
      display: none;
    }
  }
  .link-wrap {
    position: absolute;
    bottom: 0;
    right: 30px;
    width: 240px;
    padding: 0;
    margin: 0;
    ul {
      flex-direction: column;
      li {
        width: 100%;
        border: 1px solid var.$c5;
        margin: 0;
        box-shadow: 0px 4px 12px #0000000d;
        &:not(:first-of-type) {
          margin-top: 5px;
        }
        a {
          font-size: 10px;
          padding: 10px;
          height: 40px;
        }
      }
    }
    @include var.sp {
      display: none;
    }
  }
}
.pageScroll {
  overflow: auto;
}
.container {
  @include var.pc {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  @include var.sp {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  .desc,
  .map-image {
    @include var.pc {
      width: 610px;
      height: 640px;
      > * {
        width: 100%;
        > * {
          width: 100%;
          > * {
            width: 100%;
          }
        }
      }
    }
    img {
      width: 100%;
      @include var.sp {
        height: 100%;
      }
    }
  }
  .desc {
    position: relative;
    .btn-wrap {
      position: absolute;
      bottom: 90px;
      left: 0;
      margin-top: 0;
    }
    @include var.sp {
      margin-top: calc(140px + 46px);
      height: 110vw;
      .btn-wrap {
        bottom: 5%;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        -moz-transform: translate3d(-50%, 0, 0);
        -webkit-transform: translate3d(-50%, 0, 0);
      }
    }
  }
  .map-image {
    position: relative;
    background: #fff;
    display: none;
    > div {
      > div {
        cursor: default !important;
      }
    }
    @include var.sp {
      height: calc(100vh - 82px);
      width: 100%;
      will-change: transform;
      > * {
        width: 100%;
        > * {
          width: 100%;
          display: flex;
          align-items: inherit;
          justify-content: center;
          > * {
            width: 100%;
          }
        }
      }
    }
    &.show {
      display: block;
    }
    .route {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &.active {
        display: block;
        opacity: 1;
        animation-duration: 0.5s;
        animation-name: fade-in;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-name: fade-in;
      }
    }
  }
  .desc {
    &-image {
      @include var.sp {
        width: 100%;
      }
    }
    &-text {
      margin-top: 15px;
      @include var.font2r;
      font-size: 12px;
      line-height: 1.2;
    }
    &-text,
    .btn-wrap {
      @include var.sp {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    img {
      display: block;
      &.inactive {
        display: none;
      }
    }
    .btn-wrap {
      justify-content: left;
      @include var.sp {
        justify-content: space-around;
      }
      .btn-regular {
        max-width: 190px;
        width: 45%;
        @include var.sp {
          height: 58px;
          padding: 10px;
        }

        &:not(:first-of-type) {
          @include var.pc {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .section-map {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    pointer-events: none;
    @include var.sp {
      width: 100%;
      height: 100%;
    }
    g {
      path,
      polygon {
        stroke-width: 0.5px;
        fill: transparent;
        stroke: transparent;
      }
      &.selected {
        path,
        polygon {
          stroke: var.$mapHover;
          stroke-width: 2px;
          fill: var.$mapClick;
          fill-opacity: 1;
        }
      }
    }
    &.section-map-selectable {
      pointer-events: auto;
      g {
        path,
        polygon {
          cursor: pointer;
        }
      }
    }
  }
  .notTouchDevice {
    .section-map {
      &.section-map-selectable {
        g {
          path,
          polygon {
            &:hover {
              stroke: var.$mapHover;
              stroke-width: 2px;
            }
          }
        }
      }
    }
  }
  .map-btn-wrap {
    position: absolute;
    width: 40px;
    bottom: 5px;
    right: 0;
    transform: translate3d(calc(100% + 20px), 0, 0);
    -moz-transform: translate3d(calc(100% + 20px), 0, 0);
    -webkit-transform: translate3d(calc(100% + 20px), 0, 0);
    @include var.sp {
      display: block;
      position: fixed;
      width: 36px;
      height: 80px;
      bottom: 0;
      right: 20px;
      transform: translate3d(0, -50%, 0);
      -moz-transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
    }
    .plus-map-btn,
    .minus-map-btn {
      position: relative;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: #fff;
      color: var.$bg4;
      border: solid 3px var.$bg4;
      transition: all var.$ease 0.6s;
      color: transparent;
      @include var.sp {
        width: 36px;
        height: 36px;
      }
      @include var.pc {
        &:hover {
          background: var.$bg4;
          border: solid 3px var.$bg4;
          transition: all var.$ease 0.6s;
          &::before,
          &::after {
            background: #fff;
          }
        }
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 45%;
        height: 2px;
        background: var.$bg4;
        transform: translate3d(-50%, -50%, 0);
        -moz-transform: translate3d(-50%, -50%, 0);
        -webkit-transform: translate3d(-50%, -50%, 0);
      }
      &::after {
        width: 2px;
        height: 45%;
      }
      &:disabled {
        opacity: 0.6;
        cursor: auto;
        background: #fff !important;
        &::before,
        &::after {
          background: var.$bg4 !important;
        }
      }
    }
    .minus-map-btn {
      margin-top: 20px;
      &::after {
        display: none;
      }
      @include var.sp {
        margin-top: 12px;
      }
    }
  }
  .tab {
    z-index: 1;
    @include var.font2b();
    @include var.pc {
      width: 400px;
      margin-top: 40px;
      margin-left: -40px;
      box-shadow: 0px 4px 12px var.$bg6;
    }
    &-wrap {
      display: flex;
      align-items: center;
      pointer-events: none;
      background: var.$bg7;
      @include var.sp {
        height: 46px;
        display: none;
        padding-top: 4px;
        background: var.$attention;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 4px 12px #00000029;
      }
      &.active {
        pointer-events: auto;
        @include var.sp {
          display: flex;
          position: absolute;
          top: 140px;
          left: 0;
          width: 100%;
        }
        li {
          button {
            cursor: pointer;
            color: #fff;
            transition: all var.$ease 0.6s;
            @include var.pc {
              color: var.$c5;
              &:hover {
                background: var.$attention;
                color: #fff;
                transition: all var.$ease 0.6s;
              }
            }
          }
          &.active {
            @include var.sp {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate3d(-50%, 100%, 0);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 8.5px 0 8.5px;
                border-color: var.$attention transparent transparent transparent;
              }
            }
            button {
              background: var.$attention;
              color: #fff;
              transition: all var.$ease 0.6s;
            }
          }
        }
      }
      li {
        width: calc(100% / 4);
        display: flex;
        flex-flow: column;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        @include var.sp {
          height: 42px;
          button {
            background: #bababa;
          }
          &:first-of-type {
            button {
              border-radius: 0 0 0 10px;
            }
          }
          &:nth-of-type(4) {
            button {
              border-radius: 0 0 10px 0;
            }
          }
        }
        button {
          border: none;
          height: 80px;
          width: 100%;
          font-size: 12px;
          line-height: 1.3;
          text-align: center;
          @include var.font2b();
          color: #c7c7c7;
          @include var.minSp() {
            font-size: 2.8vw;
          }
        }
      }
    }
    &-select,
    &-search {
      padding: 35px 20px;
      background: #fff;
    }
    &-select {
      box-shadow: 0px 4px 12px var.$bg6;
      @include var.sp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 140px;
        display: flex;
        align-items: center;
        padding: 13px 10px;
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: left;
        &-text {
          p {
            @include var.sp {
              margin-top: 2px;
              margin-bottom: 2px;
            }
          }
          .listmark {
            &:first-of-type {
              @include var.pc {
                margin-top: 5px;
              }
            }
          }
        }
      }
      .type {
        font-size: 16px;
        @include var.minSp() {
          font-size: 3.8vw;
        }
      }
    }
    &-search {
      @include var.sp {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        padding: 20px;
        border-radius: 18px;
        box-shadow: 0px 6px 12px var.$bg6;
        width: 92%;
        &.close {
          display: none;
        }
        .modal-close {
          top: 11px;
          right: 11px;
          width: 32px;
          height: 32px;
          background: transparent;
          &::before,
          &::after {
            width: 15px;
          }
        }
      }
      .box-text {
        color: var.$c5;
        &-ttl {
          font-size: 16px;
          line-height: 1.2;
          letter-spacing: 1.28px;
          @include var.sp {
            letter-spacing: 1.44px;
          }
          @include var.minSp() {
            font-size: 4.34vw;
          }
        }
        .text {
          @include var.font2m();
          margin-top: 18px;
          font-size: 12px;
          line-height: 1.75;
          letter-spacing: 0.96px;
          @include var.minSp() {
            font-size: 2.89vw;
          }
        }
      }
    }
    .select {
      &-label {
        margin-right: 10px;
        @include var.minSp() {
          font-size: 2.89vw;
          padding: 2vw;
        }
      }
      &-desc {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top: 10px;
        @include var.sp {
          font-size: 14px;
          margin-top: 0;
        }
        @include var.minSp() {
          font-size: 3.38vw;
        }
        .select-desc-item + .select-desc-item {
          margin-left: 1em;
          white-space: nowrap;
        }
      }
      &-description {
        font-weight: normal;
        @include var.minSp() {
          font-size: 2.5vw;
        }
      }
    }
  }
}
.block-map {
  background: #fff;
  @include var.pc {
    padding: 40px;
  }
}
.block-map,
.map-image {
  @include var.pc {
    border: 1px solid var.$grey2;
  }
}
.spZoomModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    width: 100%;
    max-height: 80vh;
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
      max-height: 70vh;
    }
    .modal-close {
      right: var.$zoomModalSide;
      border-color: #fff;
      background: none;
      top: -10%;
      width: 38px;
      height: 38px;
      &::before,
      &::after {
        width: 14px;
        background: #fff;
      }
    }
    .text-inner {
      > p {
        display: inline-block;
        text-align: left;
      }
    }
    p {
      font-size: 16px;
      letter-spacing: 1.28px;
      line-height: 2;
      color: #fff;
    }
  }
  &.is-active {
    display: block;
  }
}
.course {
  display: none;
  width: 81% !important;
  position: absolute;
  left: 0;
  padding: 8px 15px;
  background: rgba(255, 255, 255, 0.8);
  @include var.pc {
    bottom: 0;
  }
  @include var.sp {
    position: absolute !important;
    width: 100% !important;
    top: 140px;
    left: 50%;
    height: calc(58px + 48px);
    transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -webkit-transform: translate3d(-50%, 0, 0);
    background: #fff;
    padding-top: calc(46px + 8px);
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: left;
    @include var.sp {
      justify-content: center;
    }
  }
  &-desc {
    width: auto !important;
    display: flex;
    align-items: center;
    justify-content: left;
    @include var.font2m;
    font-size: 10px;
    @include var.sp {
      font-size: 2.41vw;
    }
    &:nth-of-type(2) {
      @include var.pc {
        margin-left: 20px;
      }
      @include var.sp {
        margin-left: 10px;
      }
      .course-symbol {
        &:first-of-type {
          background: var.$bg11;
        }
        &:nth-of-type(3) {
          border: 3px var.$bg11 solid;
        }
        &:nth-of-type(2) {
          background: var.$bg11;
        }
      }
    }
  }
  &-symbol {
    display: flex;
    align-items: center;
    justify-content: left;
    &:first-of-type,
    &:nth-of-type(3) {
      width: 14px !important;
      height: 14px;
      border-radius: 50%;
      flex-shrink: 0;
    }
    &:first-of-type {
      background: var.$bg10;
    }
    &:nth-of-type(3) {
      background: #fff;
      border: 3px var.$bg10 solid;
    }
    &:nth-of-type(2) {
      width: 22px !important;
      height: 3px;
      background: var.$bg10;
    }
  }
  &-name {
    margin-left: 5px;
    p {
      font-size: 10px;
      &:first-of-type {
        font-weight: bold;
      }
    }
  }
  &.active {
    display: block;
  }
}

.stadium-bg {
  opacity: 0;
  transition: opacity 800ms var.$ease;
  &.show {
    opacity: 1;
    transition: opacity 800ms var.$ease;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  &-text {
    text-align: center;
    margin-top: 10px;
    color: var.$c4;
    opacity: 0.6;
    font-size: 14px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-style: italic;
    padding-left: 1em;
  }
}

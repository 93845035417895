@use './setting.scss' as var;

.Edit-root {
  .map-image {
    display: block;
  }
  .tab-select {
    @include var.sp {
      top: 0;
      height: 186px;
      overflow: scroll;
    }
  }
}
.SearchPositionEditor-root {
  margin-top: 16px;
  .input-sm {
    @include var.sp {
      width: 45%;
    }
  }
}
